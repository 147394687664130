<template>
  <div>
    <b-form-group
      v-if="institution && institution.show_evaluation_criteria_macro"
      :label="`${$getVisibleNames(
        'teaching.evaluationcriteriamacro',
        true,
        'Criterios de Evaluación Macro'
      )}:`"
      label-for="select-evaluation-criterias-macro"
      label-cols="0"
      label-cols-sm="3"
      class="select-form-group my-1"
    >
      <template v-if="evaluationCriteriasMacrosFilter.length > 0">
        <b-form-select
          id="select-evaluation-criterias-macro"
          v-model="$v.question.evaluation_criterias_macro.$model"
          multiple
          size="sm"
          class="select-form"
        >
          <b-form-select-option
            class="select-option-macro"
            v-for="item in evaluationCriteriasMacrosFilter"
            :key="item.id"
            :value="item.id"
          >
            {{ item.order }} - {{ item.full_sentence }}
          </b-form-select-option>
        </b-form-select>
      </template>
      <template v-else>
        <span
          v-b-tooltip.v-secondary.noninteractive="
            `La ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )} no cuenta con ${$getVisibleNames(
              'teaching.evaluationcriteriamacro',
              true,
              'Criterios de Evaluación Macro'
            )}`
          "
        >
          N/A
        </span></template
      >
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames(
        'teaching.evaluationcriteriamicro',
        true,
        'Criterios de Evaluación Micro'
      )}:`"
      label-for="select-evaluation-criterias-micro"
      label-cols="0"
      label-cols-sm="3"
      class="select-form-group my-2 border-bottom"
    >
      <template v-if="evaluationCriteriasMicrosFilter.length > 0">
        <!-- <b-form-select
          id="select-evaluation-criterias-micro"
          :options="evaluationCriteriasMicrosFilter"
          v-model="$v.question.evaluation_criterias_micro.$model"
          text-field="full_sentence"
          value-field="id"
          multiple
          size="sm"
        >
        </b-form-select> -->
        <b-form-select
          v-model="$v.question.evaluation_criterias_micro.$model"
          :select-size="
            evaluationCriteriasMicrosFilter.length > 6
              ? 6
              : evaluationCriteriasMicrosFilter.length + 1
          "
          size="sm"
          multiple
          class="select-form mb-2"
        >
          <b-form-select-option-group
            v-for="item in filterECMicro"
            :key="item.id"
            :label="item.name"
          >
            <b-form-select-option
              class="select-option-micro"
              v-for="ecm in item.options"
              :value="ecm.id"
              :key="ecm.id"
              :title="ecm.name"
            >
              {{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
            </b-form-select-option>
          </b-form-select-option-group>
        </b-form-select>
      </template>
      <template v-else>
        <span
          v-b-tooltip.v-secondary.noninteractive="
            `La ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )} no cuenta con ${$getVisibleNames(
              'teaching.evaluationcriteriamicro',
              true,
              'Criterios de Evaluación Micro'
            )}`
          "
        >
          N/A
        </span></template
      >
    </b-form-group>
    <b-form-group
      label="Tipo de Pregunta"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 my-1 p-0"
    >
      <b-form-select
        id="select-type"
        :options="questionTypes"
        v-model="$v.question.question_type.$model"
        :state="validateState('question_type')"
        text-field="value"
        value-field="id"
        aria-describedby="select-type-feedback"
      >
      </b-form-select>
      <b-form-invalid-feedback id="select-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Enunciado"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 my-2 p-0"
    >
      <div
        class="question-title rich-text-content"
        :class="{
          'empty-alert': $v.question.title.$error,
          'check-alert': !$v.question.title.$error && $v.question.title.$dirty,
        }"
        v-html="$v.question.title.$model"
        @click="$bvModal.show(`edit-question-title-modal`)"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
      ></div>
      <!-- <div class="alert-icon">
        <b-icon  icon="exclamation-circle"></b-icon>
        </div>       -->
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
      <b-modal
        :id="`edit-question-title-modal`"
        title="Redactar enunciado de la pregunta."
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="question"
          :Text="question.title"
          @save="patchQuestionTitle"
          @close="$bvModal.hide(`edit-question-title-modal`)"
        ></NewRichTextEditor>
      </b-modal>
      <!-- <b-form-input
        id="input-title"
        v-model="$v.question.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      > -->
      <div v-if="$v.question.title.$error" class="error-text">
        Este campo es obligatorio.
      </div>
    </b-form-group>
    <!-- Puntaje -->
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 my-1 p-0"
    >
      <div
        class="question-description rich-text-content"
        v-html="$v.question.description.$model"
        @click="$bvModal.show(`edit-question-description-modal`)"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
      ></div>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
      <b-modal
        :id="`edit-question-description-modal`"
        title="Redactar descripción de la pregunta."
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="question"
          :Text="question.description"
          @save="patchQuestionDescription"
          @close="$bvModal.hide(`edit-question-description-modal`)"
        ></NewRichTextEditor>
      </b-modal>
      <!-- <b-form-input
        id="input-description"
        v-model="$v.question.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-input> -->
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Puntaje"
      label-for="input-score"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 my-2 p-0"
    >
      <b-form-input
        id="input-score"
        type="number"
        v-model="$v.question.score.$model"
        :state="validateState('score')"
        aria-describedby="input-score-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback"
        >Este campo no debe ser menor a 1.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Nivel Cognitivo"
      label-for="cognitive-level"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="cognitive-level"
        v-model="$v.question.cognitive_level.$model"
        :state="validateState('cognitive_level')"
        aria-describedby="cognitive-level-feedback"
      >
        <b-form-select-option
          v-for="option in cognitive_levels"
          :key="option.id"
          :value="option.id"
        >
          {{ option.level + ". " + option.name }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <div class="text-right">
      <b-button class="px-3 mt-2 mr-1" size="sm" @click="saveQuestion"
        >Guardar</b-button
      >
    </div>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { required, minValue } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    Question: {
      type: Object,
    },
    question_test_id: {
      type: Number,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    default_order: {
      type: Number,
    },
  },
  data() {
    return {
      question: {
        id: this.Question ? this.Question.id : generateUniqueId(),
        title: this.Question ? this.Question.title : "",
        description: this.Question ? this.Question.description : "",
        feedback: this.Question ? this.Question.feedback : "",
        score: this.Question ? this.Question.score : 1,
        order: this.Question ? this.Question.order : this.default_order,
        question_test: this.Question
          ? this.Question.question_test
          : this.question_test_id,
        question_type: this.Question ? this.Question.question_type : null,
        cognitive_level: this.Question ? this.Question.cognitive_level : null,
        contents: this.Question ? this.Question.contents : [],
        images: this.Question ? this.Question.images : [],
        evaluation_criterias_macro: this.Question
          ? this.Question.evaluation_criterias_macro
          : [],
        evaluation_criterias_micro: this.Question
          ? this.Question.evaluation_criterias_micro
          : [],
      },
      selected_question_type: null,
      cognitive_levels: [],
    };
  },
  validations() {
    return {
      question: {
        question_type: { required },
        title: { required },
        description: {},
        score: { minValue: minValue(1) },
        cognitive_level: {},
        evaluation_criterias_macro: {},
        evaluation_criterias_micro: {},
        // sub_type: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
      questionTypes: names.QUESTION_TYPES,
      section_evaluations: names.SECTION_EVALUATIONS,
      questions: names.QUESTIONS,
      institution: "getInstitution",
    }),
    storedQuestion() {
      if (!this.Question) return;
      return this.questions.find((x) => x.id == this.Question.id);
    },
    evaluationCriteriasMacrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_macro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMacros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    evaluationCriteriasMicrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_micro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMicros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias =
          this.evaluationCriteriasMicrosFilter.filter(
            (x) => x.study_unit == study_unit.id
          );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
            options: this.evaluationCriteriasMicrosFilter
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    filter_section_evaluation() {
      return this.section_evaluations.filter(
        (x) => x.evaluation == this.evaluation_id
      );
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.question[key];
      return $dirty ? !$error : null;
    },
    saveQuestion() {
      // this.$refs.redaction_question.createSimpleQuestion();
      this.$v.question.$touch();
      if (this.$v.question.$anyError) {
        return;
      }
      if (isNaN(this.question.id)) this.createQuestion();
      else this.updateQuestion();
    },
    createQuestion() {
      if (this.question.question_type == 2) {
        this.$store
          .dispatch(names.POST_MULTIPLE_CHOICE_QUESTION, this.question)
          .then((response) => {
            this.question = response;
            this.$emit("created", this.question);
            this.$bvModal.hide(`modal-add-question-${this.question_test_id}`);
            toast("Pregunta guardada.");
          });
      } else {
        this.$store
          .dispatch(names.POST_QUESTION, this.question)
          .then((response) => {
            this.question = response;
            this.$emit("created", this.question);
            this.$bvModal.hide(`modal-add-question-${this.question_test_id}`);
            toast("Pregunta guardada.");
          });
      }
    },
    updateQuestion() {
      if (this.question.question_type == this.storedQuestion.question_type) {
        let temp_question_score = this.Question.score;
        temp_question_score =
          parseInt(this.question.score) - temp_question_score;
        this.$store
          .dispatch(names.UPDATE_QUESTION, this.question)
          .then((response) => {
            this.$emit("updated", response);
            this.$emit("questionScoreUpdated", temp_question_score);
            toast("Pregunta actualizada.");
          });
      } else if (
        this.question.question_type < this.storedQuestion.question_type
      ) {
        let temp_question_score = this.Question.score;
        temp_question_score =
          parseInt(this.question.score) - temp_question_score;
        this.$store
          .dispatch(names.DELETE_QUESTION, this.question.id)
          .then(() => {
            this.$store
              .dispatch(names.POST_QUESTION, this.question)
              .then((response) => {
                this.question = response;
                this.$emit("updated", response);
                this.$emit("questionScoreUpdated", temp_question_score);
                toast("Pregunta actualizada.");
              });
          });
      } else {
        this.$store
          .dispatch(names.DELETE_QUESTION, this.question.id)
          .then(() => {
            this.$store
              .dispatch(names.POST_MULTIPLE_CHOICE_QUESTION, this.question)
              .then((response) => {
                this.question = response;
                this.$emit("updated", this.question);
                toast("Pregunta actualizada.");
              });
          });
      }
    },
    fetchCognitiveLevels() {
      this.$restful.Get("/mesh/cognitive-level/").then((response) => {
        this.cognitive_levels = response;
      });
    },
    patchQuestionTitle(question, text) {
      this.question.title = text;
    },
    patchQuestionDescription(question, text) {
      this.question.description = text;
    },
  },
  created() {
    this.fetchCognitiveLevels();
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.question-title,
.question-description {
  padding: 0 0.5em;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-height: 2.36em;
  cursor: text;
  resize: vertical;
  overflow: auto;
}
.empty-alert {
  border: 1px solid #dc3545;
}
.check-alert {
  border: 1px solid #28a745;
}
.alert-icon {
  margin-left: auto;
  margin-right: 0;
}
.select-form-group {
  margin-inline: 0.05em;
}
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: -16px;
}
.error-text {
  color: #dc3545;
  font-size: 10pt;
  margin-top: 0.2em;
}
</style>